import http from './Http'
import tokenSvc from './TokenService'

class AssetService {

	static getAssetDetails(params, callback) {
		const token = tokenSvc.getToken()
		http.get('assets/' + params, {'x-access-token-workoutaholic': token}, (res) => {
			callback(res)
		})
	}
}

export default AssetService