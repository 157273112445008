import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import AuthService from '../../services/AuthService'
import { Container, Row, Col } from 'react-grid-system'

class PwdResetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      success: false
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.header = function() {
      if(this.props.dissolve) {
        return(<></>)
      } else {
        return(<h1>Reset Password</h1>)
      }
    }
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }

  handleSubmit(event) {
    console.log(this.state.email)
    AuthService.requestPasswordReset({ email: this.state.email }, res => {
      const prefix = (res.message && res.success && res.success === true) ? "Success: " : "Error: "
      alert(prefix + res.message)
      // console.log(prefix)
      // console.log(res)
      if(res.success === true) {
        this.setState({ success:  true })
      }
    })
    event.preventDefault();
  }

  render() {
    if(this.state.success) {
      // need to handle redirect back
      console.log(this.props)
      if(this.props.dissolve) {
        this.props.onSuccess()
      } else {
        return <Redirect to={ "/login" } /> //if not go to account - not admin`````````
      }
    }
    return (
      <Container>
      { this.header() }
      <div className="Enter-screen">

      <Row className="pad-top-30">
        <Col xs={12}>
          <form className="center-text jumbotron" onSubmit={this.handleSubmit}>
            
            <h3>Enter your email to be sent a link to reset your password</h3>
            <label>
              Email<br />
              <input type="text" value={this.state.email} autoCapitalize="none" autoComplete="email" onChange={this.handleEmailChange} className="form-element" />
            </label>
            <br />
            <br />
            <input type="submit" value="Submit" className="submit-btn"/>
     
          </form>
        </Col>
      </Row>

      </div>
      </Container>
    );
  }
}

export default PwdResetForm;
