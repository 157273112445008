import http from './Http'

class MailerService {

	static sendMessage(contactBody, callback) {
		http.post('contact', contactBody, null, (res) => {
			console.log("back from contact call" + res)
			callback(res)
		})
	}
}

export default MailerService