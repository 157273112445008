import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-grid-system'

//import HTMLComponent from './HTMLComponent'
import LoadingIndicator from '../shared/LoadingIndicator'

import ClassService from '../../services/ClassService'

class ClassList extends Component {

	constructor(props) {
    super(props)
    this.state = {
      listItems: [],
      loading: true
    }
  }

  componentDidMount() {

    ClassService.getClasses(res => {

      this.setState({
        loading: false,
        listItems: res.map((item, index) =>
                      <Row key={index} >
                        <div className="list-item-separator floatLeft">
                          <Col xs={12} sm={5} lg={4} className="floatLeft">
                            <Link to={'classes/' + item.slug}><img className="image-rounded" src={item.thumb._url} alt={item.thumb.name} /></Link>
                          </Col>
                          <h3><Link to={'classes/' + item.slug}>{item.name}</Link></h3>
                          {item.intro}
                        </div>
                      </Row>
                    ) 
      })
    })
  }

  render() {
    return (
    		<Container>
        <h1>Recordings</h1>
        {this.state.loading === true ? (
              <LoadingIndicator />
           ) : (
              <div className="Enter-screen">
                <div className="pad-top-30">
                  {this.state.listItems}
                </div>
              </div>
           )}
        </Container>
    )
  }
}


export default ClassList;
