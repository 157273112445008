import http from './Http'

class ArticleService {

	static getArticles(callback) {
		http.get('articles', {}, (res) => {
			callback(res.filter(article => article.published === true))
		})
	}

	static getArticleDetails(params, callback) {

		http.get('articles/' + params, {}, res => {
			callback(res)
		})
	}

	static getArticleAdminDetails(params, callback) {

		http.get('articles/' + params, {}, res => {
			callback(res)
		})
	}

	// static createArticle(params, callback) {

	// 	http.post('articles', res => {
	// 		callback(res)
	// 	})
	// }
}

export default ArticleService