import React, { Component } from 'react'
import AuthSvc from '../../services/AuthService'

export default function withAuth(AuthComponent) {

  // If user not authenticated render out to root

  return class AuthWrapped extends Component {

    constructor(props, match) {
      super(props)
      this.state = {
          user: null
      }
    }

    componentDidMount() {
      if (!AuthSvc.loggedIn()) {
        //AuthSvc.logout()
        this.props.history.replace('/login')
      }
      else {

        AuthSvc.getUser(res => {
          if(res.success === false) { this.props.history.replace('/login') }

          else {
            this.setState({
              user: res
            })
          }
        })
      }
    }

    render() {
      
      if (this.state.user) {

        return (
            <AuthComponent redirect={this.props.redirect} match={this.props.match} history={this.props.history} user={this.state.user} />
        )
      }
      else {
          return null
      }
    }
  }

}