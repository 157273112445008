import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import AuthSvc from '../../services/AuthService'

class NavigationBar extends Component {

  constructor(props) {
    super(props)

    //this.handleClickLink = this.handleClickLink.bind(this)
    this.handleClickHamburger = this.handleClickHamburger.bind(this)

    this.state = { visible: false, initialLoad: true }

    this.navItems = [{
        id: 1,
        path: '/',
        pathName: "Home"
      },
      {
        id: 2,
        path: '/classes',
        pathName: "Recordings"
      },
      {
        id: 3,
        path: '/about',
        pathName: "About"
      },
      {
        id: 4,
        path: '/contact',
        pathName: "Contact"
      }]

      
    
      // {
      //   id: 5,
      //   path: '/login',
      //   pathName: "Login"
      // }
      // {
      //   id: 2,
      //   path: '/articles',
      //   pathName: "Articles"
      // },
    this.navLinks = this.navItems.map((navItem) =>
      <li onClick={ this.handleClickHamburger } key={navItem.id}><Link to={navItem.path}>{navItem.pathName}</Link></li>
    )

    this.drawHamburger = function() {
      return (
        <div id={'App-hamburger'} className={this.state.visible ? 'slideOutBurger' : 'slideInBurger'} onClick={ this.handleClickHamburger }>
          <div className="center">
          <img alt="Hambuerger" className="image-rounded" width="100%" src="https://yoga-jams.s3.amazonaws.com/ohm-symbol.png" />
          </div>
        </div>
      )
    }

    this.drawMenu = function() {
      if(this.state.initialLoad)
        return(<div id="App-side-menu"></div>)
      else
        return(
          <div id={"App-side-menu"} className={ this.state.visible ? 'slideIn' : 'slideOut' } >
            <ul className="Header-list">
              {this.navLinks}
              { AuthSvc.loggedIn() ? <><li onClick={ this.handleClickHamburger } key={5}><Link to={'/myAccount'}>Account</Link></li></> : <></> }
            </ul>
            <ul className="Header-list">
              { AuthSvc.loggedIn() ? <li className="pad-top-30" onClick={ this.logOut } key={'LOGOUT'}>Log Out</li> : <li key={'LOGIN'}><Link onClick={ this.handleClickHamburger }  to='/login'>Log In</Link></li> }
            </ul>
          </div>
        )
    }

   

    this.drawNavigation = function() {
      return(
        <div>
          { this.drawMenu() }
          { this.drawHamburger() }
        </div>
      )
    }

    this.logOut = function() {
      AuthSvc.logOut()
      window.location.reload()
    }
  }

  handleClickHamburger() {
    this.setState(prev => ({ visible: !prev.visible, initialLoad: false }))
  }

  render() {
    return (
      this.drawNavigation()
    )
  }
}

export default NavigationBar;