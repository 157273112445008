import axios from 'axios'
import tokenSvc from './TokenService'
import environment from './Environment'

const config = environment.config

class UploadAsset {

	static getSignedRequest(imageData, callback) {
		const token = tokenSvc.getToken()
    var xhr = new XMLHttpRequest()
    xhr.open("GET", config.baseURL + "admin/sign_s3?file_name="+imageData._file_upload.name.replace(/\s/g, '')+"&file_type="+imageData._file_upload.type);
    xhr.setRequestHeader('x-access-token-workoutaholic', token)
    xhr.onreadystatechange = function(){

        if(xhr.readyState === 4){
            if(xhr.status === 200){
            		console.log(xhr.responseText)
                var response = JSON.parse(xhr.responseText)
                console.log(response)
                callback(response)
                //UploadFile.upload_image(imageData.photo, response.signed_request, response.url);
                // No return because because I can't figure out return scope - db is only updated if successful, otherwise an alert says there was an error
            }
            else{
            	console.log(xhr)
                alert("Could not sign request. Please try again later."); // couldnt get signed url from s3
            }
        }
    }
    xhr.send()
	}

	static uploadFile(model, signed_request, url, callback) {
		var xhr = new XMLHttpRequest()
    xhr.open("PUT", signed_request)
    xhr.setRequestHeader('x-amz-acl', 'public-read')
    xhr.onload = function() {
        if (xhr.status === 200) {
            //Status 200 - successful upload of file
            console.log('Successful Image Upload')

            //THIS IS A SIMPLE CALL TO ACTUALLY UPDATE THE DB

		    const token = tokenSvc.getToken()
            var response = axios.post(config.baseURL + 'admin/assets', 
													{ 'url': url,
														'name': model.name,
														'slug':model.slug
														},
														{ headers: { 
															'x-access-token-workoutaholic': token
														}
														})
            callback(response)
            
        }
    };
    xhr.onerror = function() {
    		console.log(xhr)
        alert("Could not upload file. Please try again later.")
    };
    xhr.send(model._file_upload)
	}
}

export default UploadAsset