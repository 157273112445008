import React, { Component } from 'react'
import { Link } from 'react-router-dom'

//import HTMLComponent from './HTMLComponent'
import ArticleService from '../../services/ArticleService'

class ArticleList extends Component {

	constructor(props) {
    super(props)

    ArticleService.getArticles(res => {

      this.setState(
        this.listItems = res.map((item, index) =>
          <div key={index}>
            <h3><Link to={'articles/' + item.slug}>{item.name}</Link></h3>
            {item.intro}
          </div>
        ) 
      )
    })
  }

  render() {
    return (
    		<div>
        <h1>Articles</h1>
        <div className="Enter-screen">{this.listItems}</div>
        </div>
    )
  }
}


export default ArticleList;
