import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-grid-system'

import withAuth from '../shared/RequireAuth'
import LoadingIndicator from '../shared/LoadingIndicator'

import AdminService from '../../services/AdminService'

class HomeAdmin extends Component {

	constructor(props) {
    super(props);
    this._isMounted = false
   	this.baseUrl = '/admin/'
   	this.state = {
   		listItems: [],
   		orders: [],
   		orderTotal: 0,
   		listLoading: true,
   		ordersLoading: true
   	}
  }

  componentDidMount() {

  	this._isMounted = true

  	AdminService.getModels(res => {
   
   		if(this._isMounted === true) {

   			this.setState({
		    	ordersLoading: false,
		    	listItems: res.files.map((file, index) => 

		    		<Col sm={6} lg={4} key={index} className="floatLeft">
			        <div className="jumbotron">
			        	<div className="jumbotron-text">
			            <Link to={this.baseUrl + file.split(".")[0]}><h2>{file}</h2></Link>
			            </div>
			        </div>
		      	</Col>
			     )

	    	})
   		}
    })

    AdminService.getOrderReport(res => {
    	if(this._isMounted === true) {

   			this.setState({
		    	listLoading: false,
		    	orderTotal: res.map((order) => {
		    		return order.amount
		    	}).reduce((total, num) => { 
		    		return total + num }, 0),
		    	orders: res.map((order, index) => 
		    		<tr key={index}>
	            <td><Link to={ this.baseUrl + 'orders/' + order._id }>{order.orderDate.substring(0, 10) + ' - $' + order.amount }</Link></td>
	            <td><Link to={ this.baseUrl + 'users/' + order.user._id }>{order.user.email}</Link></td>
	            <td><Link to={ this.baseUrl + 'classs/' + order.yogaClass._id }>{order.yogaClass.name}</Link></td>
          	</tr>
			     )

	    	})
   		}
    })
  }

  componentWillUnmount() {
  	this._isMounted = false
  }

	render() {
		return (
				<Container>
				<Row>
			    <h1>Admin Home</h1>
	        	<div className="Enter-screen">
					    {this.state.listLoading === true ? (
			            <LoadingIndicator />
			         ) : (
			            	<Row>
			            		{this.state.listItems}
			            	</Row>
			         )}

			         {this.state.ordersLoading === true ? (
			            <LoadingIndicator />
			         ) : (
			         			<>
			         			<h2>Orders Total: ${ this.state.orderTotal }</h2>
			            	<table className="table pad-top-30 floatLeft">
						          <thead>
						            <tr>
						              <th>Order (Date & Amount)</th>
						              <th>User</th>
						              <th>Class</th>
						            </tr>
						          </thead>
						          <tbody>
						            {this.state.orders}
						          </tbody>
						        </table>
						        </>
			         )}

	        	 </div>
			  </Row>
			  </Container>
		)
	}
}

export default withAuth(HomeAdmin)
