import http from './Http'
import tokenSvc from './TokenService'

class AdminService {

	static getModels(callback) {

		var key = tokenSvc.getToken()
		http.get('admin/schemas', {'x-access-token-workoutaholic': key}, (res) => {
			callback(res)
		})
	}

	static getModelsList(params, callback) {

		var key = tokenSvc.getToken()
		http.get(params, {'x-access-token-workoutaholic': key}, (res) => {
			callback(res)
		})
	}

	static getModelsListSync(params) {

		var key = tokenSvc.getToken()
		return http.getAwait(params, {'x-access-token-workoutaholic': key})
	}

	static getModelsDetails(model, params, callback) {

		var key = tokenSvc.getToken()
		http.get('admin/' + model + '/' + params, {'x-access-token-workoutaholic': key}, (res) => {

			callback(res)
		})
	}

	static postModel(model, body, callback) {
		var key = tokenSvc.getToken()
		http.post(model, body, {'x-access-token-workoutaholic': key}, (res) => {

			callback(res)
		})
	}

	static putModel(model, params, body, callback) {
		var key = tokenSvc.getToken()
		http.put(model, params, body, {'x-access-token-workoutaholic': key}, (res) => {

			callback(res)
		})
	}

	static deleteModelInstance(model, params, callback) {
		var key = tokenSvc.getToken()
		http.delete(model + '/' + params, {'x-access-token-workoutaholic': key}, (res) => {

			callback(res)
		})
	}

	static getModelSchema(model, callback) {

		var key = tokenSvc.getToken()
		http.get('admin/schemas/' + model, {'x-access-token-workoutaholic': key}, (res) => {

			callback(res)
		})
	}

	static uploadFile(model, callback) {

		var key = tokenSvc.getToken()
		http.get('admin/schemas/' + model, {'x-access-token-workoutaholic': key}, (res) => {

			callback(res)
		})
	}

	static getOrderReport(callback) {
		var key = tokenSvc.getToken()
		http.get('admin/orders', {'x-access-token-workoutaholic': key}, (res) => {
			callback(res)
		})
	}

}

export default AdminService