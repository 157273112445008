import http from './Http'
import tokenSvc from './TokenService'

class AuthService {

	static authUser(body, callback) {
		http.post('authenticate', body, {}, res => {
		
			tokenSvc.setToken(res.token)
			callback(res)
		})
	}

	static createAccount(body, callback) {
		http.post('users', body, {}, res => {
		
			tokenSvc.setToken(res.token)
			callback(res)
		})
	}

	static requestPasswordReset(body, callback) {
		http.post('pwd_reset', body, {}, res => {
			callback(res)
		})
	}

	static getUserForPasswordReset(token, callback) {
		http.get('pwd_reset/' + token, {}, res => {
			callback(res)
		})
	}

	static setPassword(body, callback) {
		http.post('set_pwd', body, {}, res => {
			callback(res)
		})
	}

	static loggedIn() {
    const token = tokenSvc.getToken()
    return !!token
  }

	static getUser(callback) {
		const token = tokenSvc.getToken()
		http.get('webMe', { 'x-access-token-workoutaholic': token }, res => {
	
			callback(res)
		})
	}

	static logOut() {
		tokenSvc.clearToken()
	}
}

export default AuthService