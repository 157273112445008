class TokenService {

	static tokenName() {
		return 'x-access-token-workoutaholic'
	}

	static setToken(token, callback) {
		if (token)
            window.localStorage.setItem(this.tokenName(), token);
        else
            this.clearToken()
	}

	static getToken() {
		return window.localStorage.getItem(this.tokenName())
	}

	static clearToken() {
		window.localStorage.removeItem(this.tokenName());
	}
}

export default TokenService