import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import AuthService from '../../services/AuthService'
import { Container, Row, Col } from 'react-grid-system'

class AuthForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginEmail: '',
      loginPassword: '',
      signUpEmail: '',
      signUpPassword: '',
      success: false
    };

    //login
    this.handleLoginEmailChange = this.handleLoginEmailChange.bind(this);
    this.handleLoginPwdChange = this.handleLoginPwdChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    //create account
    this.handleSignUpEmailChange = this.handleSignUpEmailChange.bind(this);
    this.handleSignUpPwdChange = this.handleSignUpPwdChange.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);

    this.header = function() {
      if(this.props.dissolve) {
        return(<></>)
      } else {
        return(<h1>Login</h1>)
      }
    }
  }

  handleLoginEmailChange(event) {
    this.setState({loginEmail: event.target.value});
  }

  handleLoginPwdChange(event) {
    this.setState({loginPassword: event.target.value});
  }

  handleSubmit(event) {
    AuthService.authUser({ email: this.state.loginEmail, password: this.state.loginPassword }, res => {
      console.log(res)
      if(res.success === true) {
        this.setState({success: true})
      } else {
        console.log(res.message)
        //alert('Error: ' + res.message)
      }
    })
    event.preventDefault();
  }

  handleSignUpEmailChange(event) {
    this.setState({ signUpEmail: event.target.value});
  }

  handleSignUpPwdChange(event) {
    this.setState({ signUpPassword: event.target.value});
  }

  handleSignUp(event) {
    // check regex password and email before sending
    AuthService.createAccount({ email: this.state.signUpEmail, password: this.state.signUpPassword }, res => {
      
      if(res.success === true) {
        this.setState({success: true})
      } else {
        alert('Error: ' + res.message)
      }
    })
    event.preventDefault();
  }

  render() {
    if(this.state.success) {
      // need to handle redirect back
      console.log(this.props)
      if(this.props.dissolve) {
        this.props.onSuccess()
      } else {
        return <Redirect to={ "/" } /> //if not go to account - not admin`````````
      }
    }
    return (
      <Container>
      { this.header() }
      <div className="Enter-screen">

      <Row className="pad-top-30">
        <Col xs={12} md={6}>
          <form className="center-text jumbotron" onSubmit={this.handleSubmit}>
            
            <h3>Login</h3>
            <label>
              Email<br />
              <input type="text" value={this.state.loginEmail} autoCapitalize="none" autoComplete="email" onChange={this.handleLoginEmailChange} className="form-element" />
            </label>
            <br />
            <br />
            <label>
              Password<br />
              <input type="password" value={this.state.loginPassword} autoCapitalize="none" autoComplete="password" onChange={this.handleLoginPwdChange} className="form-element" />
            </label>
            <br />
            <br />
            <input type="submit" value="Login" className="submit-btn"/>
     
          </form>
        </Col>

        <Col xs={12} md={6}>
            
            <form className="center-text jumbotron" onSubmit={this.handleSignUp}>
            <h3>Create Account</h3>
                <label>
                  Email<br />
                  <input type="text" value={this.state.signUpemail} autoCapitalize="none" autoComplete="email" onChange={this.handleSignUpEmailChange} className="form-element" />
                </label>
                <br />
                <br />
                <label>
                  Password<br />
                  <input type="password" value={this.state.signUpPassword} autoCapitalize="none" autoComplete="password" onChange={this.handleSignUpPwdChange} className="form-element" />
                </label>
                <br />
                <br />
                <input type="submit" value="Sign Up" className="submit-btn"/>
            </form>

        </Col>
      </Row>

      <div className="center-text pad-top-30">
        <Link to={'/passwordReset'}>Forgot your password?</Link>
      </div>
      </div>
      </Container>
    );
  }
}

export default AuthForm;
