import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row } from 'react-grid-system'

//import HTMLComponent from './HTMLComponent'
import LoadingIndicator from '../shared/LoadingIndicator'

import OrderService from '../../services/OrderService'

class UserAccount extends Component {

	constructor(props) {
    super(props)
    this.state = {
      listItems: [],
      loading: true
    }

    this.rentedOrBought = function(expirationDate) {
      if(expirationDate) {
        return "Rented"
      } else {
        return "Purchased"
      }
    }
  }

  componentDidMount() {

    OrderService.getOrders(res => {
      if(res.length > 0) {
        this.setState({
          loading: false,
          listItems: res.map((item, index) =>
                        <Row key={index} >
                          <div className="list-item-separator floatLeft">
                            <Link to={'classes/' + item.yogaClass.slug}>{item.yogaClass.name}</Link>&nbsp;&nbsp;-&nbsp;&nbsp;
                            { this.rentedOrBought(item.expirationDate) } on { item.orderDate }
                          </div>
                        </Row>
                      )
                      //.sort() 
         })
      } else {
        this.setState({
          loading: false,
          listItems: "No Orders"
        })
      }
      
    })
  }

  render() {
    return (
    		<Container>
        <h1>My Account</h1>
          {this.state.loading === true ? (
              <LoadingIndicator />
           ) : (
              <>
              <div className="Enter-screen">
              <h4><strong>My Classes</strong></h4>
                <div className="pad-top-10">
                  {this.state.listItems}
                </div>
              </div>
              </>
           )}
        </Container>
    )
  }
}


export default UserAccount;
