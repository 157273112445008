import React from 'react';
import ReactDOM from 'react-dom';
import scriptLoader from 'react-async-script-loader';
import LoadingIndicator from './LoadingIndicator'

import Env from '../../services/Environment'

const config = Env.config
 
let PayPalButton = null;

class PaypalButton extends React.Component {
  constructor(props) {
    super(props);
 
    this.state = {
      showButton: false,
      loading: true,
      paid: false
    };

    window.React = React;
    window.ReactDOM = ReactDOM;
  }
 
  componentDidMount() {
    const {
      isScriptLoaded,
      isScriptLoadSucceed
    } = this.props;
 
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ showButton: true });
    }
  }
 
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const scriptJustLoaded =
      !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PayPalButton = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM
        });
        this.setState({ loading: false, showButtons: true });
      }
    }
  }

  createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: this.props.description,
          amount: {
            value: this.props.total
          }
        }
      ],   
      application_context: {
        shipping_preference: 'NO_SHIPPING'
      }
    });
  };

  onApprove = (data, actions) => {
    actions.order.capture().then(details => {
      console.log('data - ', data)
      console.log('order details - ', details)
      const today = new Date()
      const expirationDate = this.props.isRental === true ? new Date(today.setDate(today.getDate() + 6)).toISOString() : null
      const paymentData = {
        payPalUserId: data.payerID,
        payPalTrxnId: data.orderID,
        amount: this.props.total,
        yogaClass: this.props.yogaClass,
        orderDate: details.create_time,
        expirationDate: expirationDate
      };
      // this will map to purchases along with loggedIn user info
      
      // const paymentData = {
      //   payerID: data.payer_id,
      //   orderID: data.id,
      //   email: data.payer.email,
      //   amount: data.purchase_units[0].amount.value
      // };
      console.log("Payment: ", details);
      console.log("Payment Data: ", paymentData);
      this.setState({ showButtons: false });
      this.props.onSuccess(paymentData)
    });
  };
 
  render() {

    const { showButtons, loading } = this.state;

    return (
      <div className="enterScreen">
        {loading && <LoadingIndicator />}

        {showButtons && (
          <div>
            <PayPalButton
              createOrder={(data, actions) => this.createOrder(data, actions)}
              onApprove={(data, actions) => this.onApprove(data, actions)}
            />
          </div>
        )}
      </div>
      );
  }
}


    // const {
    //   total,
    //   currency,
    //   env,
    //   commit,
    //   client,
    //   onSuccess,
    //   onError,
    //   onCancel,
    // } = this.props;
 
    // const {
    //   showButton,
    // } = this.state;
 
    
 
    // return (
    //   <div>
    //     {showButton && <paypal.Button.react
    //       env={env}
    //       client={client}
    //       commit={commit}
    //       payment={payment}
    //       onAuthorize={onAuthorize}
    //       onCancel={onCancel}
    //       onError={onError}
    //     />}
    //   </div>
    // );

export default scriptLoader('https://www.paypal.com/sdk/js?client-id=' + config.PAYPAL_CLIENT)(PaypalButton);
