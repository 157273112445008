import React, { Component } from 'react'

import HTMLComponent from '../shared/HTMLComponent'
import PayPalButton from '../shared/PayPalButton'
import AuthForm from '../auth/AuthForm'


import { Container, Row, Col } from 'react-grid-system'
import LoadingIndicator from '../shared/LoadingIndicator'

import ClassService from '../../services/ClassService'
import AuthService from '../../services/AuthService'
import OrderService from '../../services/OrderService'

class ClassDetails extends Component {

	constructor(props, match) {
    super(props)
    this.state = {
      props: props,
      classInfo: null,
      loading: true,
      loadingOrders: false,
      userOrders: [],
      purchasePrice: 0,
      purchaseToRent: true,
      loggedIn: AuthService.loggedIn(),
      previos: props.previous
    }
    // classInfo has a field purchasePrice which is the cost of the class
    // the purchasePrice in state is the amount the user will pay to either rent or buy which is passed on to PayPal
    // the purchaseToRent field is used to denote the paymentPreview text denoting a rental of 5 days or a purchase

    const onSuccessfulAuthentication = () => {
      window.location.reload();
    }

    const onSuccessfulPurchase = (payment) => {
      //post to users classes
      this.setState(state => {
        const userOrders = state.userOrders.concat(payment)
        return { userOrders }
      })

      OrderService.purchase(payment, (orders) => {
        if(this.state.userOrders.length === 0) {
          this.setState({ userOrders: orders})
        }
      })
    }
 
    const onError = (error) =>
      console.log('Erroneous payment OR failed to load script!', error);
 
    const onCancel = (data) =>
      console.log('Cancelled payment!', data);

    this.disclaimer = function() {
      return(<h6>After you complete your transaction the screen will look like this for 5 - 10 seconds... please be patient while the page takes its sweet time reloading.</h6>)
    }

    this.paymentPreview = function() {
      if(this.state.purchaseToRent === true) {
        return(<><h2>Rent for 5 days for ${this.state.purchasePrice}</h2>{ this.disclaimer() }</>)
      } else {
        return(<><h2>Purchase for ${this.state.purchasePrice}</h2>{ this.disclaimer() }</>)
      }
    }

    this.rentAndBuyOption = function() {
      return(<div className="pad-top-30">
              <Col xs={6} key={ "RENT" } className="floatLeft">
                <div className="jumbotron jumbotron-hover" onClick={ () => this.setState({ purchasePrice: this.state.classInfo.rentalPrice }) }>
                  <div className="jumbotron-text">
                    <h2>RENT (${ this.state.classInfo.rentalPrice })</h2>
                    5 Day Rental
                    </div>
                </div>
              </Col>
              <Col xs={6} key={ "BUY" } className="floatLeft">
                <div className="jumbotron jumbotron-hover " onClick={ () => this.setState({ purchasePrice: this.state.classInfo.purchasePrice, purchaseToRent: false }) }>
                  <div className="jumbotron-text">
                    <h2>BUY (${ this.state.classInfo.purchasePrice })</h2>
                    Unlimited Access
                    </div>
                </div>
              </Col>
            </div>)
    }

    this.buyOnlyOption = function() {
      return(<div className="pad-top-30">
              <Col xs={12} key={ "BUY" } className="floatLeft">
                <div className="jumbotron jumbotron-hover " onClick={ () => this.setState({ purchasePrice: this.state.classInfo.purchasePrice, purchaseToRent: false }) }>
                  <div className="jumbotron-text">
                    <h2>BUY (${ this.state.classInfo.purchasePrice })</h2>
                    Unlimited Access
                    </div>
                </div>
              </Col>
            </div>)
    }

    this.rentOnlyOption = function() {
      return(<div className="pad-top-30">
              <Col xs={12} key={ "RENT" } className="floatLeft">
                <div className="jumbotron jumbotron-hover " onClick={ () => this.setState({ purchasePrice: this.state.classInfo.rentalPrice, purchaseToRent: true }) }>
                  <div className="jumbotron-text">
                    <h2>RENT (${ this.state.classInfo.rentalPrice })</h2>
                    5 Day Rental
                    </div>
                </div>
              </Col>
            </div>)
    }

    this.isFreeClass = function() {
      return !this.state.classInfo.rentalPrice && !this.state.classInfo.purchasePrice
    }

    this.isValidPrice = function(price) {
      return price && !isNaN(price)
    }

    this.paymentOptions = function() {
      if(this.isValidPrice(this.state.classInfo.purchasePrice) && this.isValidPrice(this.state.classInfo.rentalPrice)) {
        return this.rentAndBuyOption()
      } else if(this.isValidPrice(this.state.classInfo.purchasePrice)) {
        return this.buyOnlyOption()
      } else if(this.isValidPrice(this.state.classInfo.rentalPrice)) {
        return this.rentOnlyOption()
      }
      console.log(isNaN(this.state.classInfo.purchasePrice))
    }

    this.videoDiv = function() {
      if(!AuthService.loggedIn()) {
        return(<><h2 className="pad-top-30 center-text">To Rent or Buy this Class, Login or Create an Account.</h2><br /><AuthForm dissolve={ true } onSuccess={ onSuccessfulAuthentication } /></>)
      } else {
        if(this.state.userOrders.length > 0 || this.isFreeClass()) {
          return(<video width="100%" className="pad-top-30" controls><source src={ this.state.classInfo.video._url } type="video/mp4" />Your browser does not support the video tag.</video>)
        } else {
          if(this.state.purchasePrice === 0.0) {
            return this.paymentOptions()
          } else {
            return(
              <div className="fullWidth pad-top-30">
              { this.paymentPreview() }
              <PayPalButton total={this.state.purchasePrice} isRental={ this.state.purchaseToRent } description={ this.state.classInfo.name } yogaClass={this.state.classInfo._id} onSuccess={onSuccessfulPurchase} onError={onError} onCancel={onCancel} /> 
              </div>
            )
          }
        }
      }
    }

    this.validRental = function(order) {
      if((order.yogaClass && order.yogaClass === this.state.classInfo._id) || (order.yogaClass._id && order.yogaClass._id === this.state.classInfo._id)) {
        if(order.expirationDate) {
          var today = new Date()
          var expirationDate = new Date(order.expirationDate)
          return expirationDate.getTime() > today.getTime()
        }
        return true
      }
      return false
    }

    this.getUserClasses = function() {
      this.setState({ loadingOrders: true })
      OrderService.getOrders((userOrders) => {
        var adjustedOrders = userOrders ?? [] // i dont handle errors so send back null w/ error and fallback to empty array
        this.setState({ loadingOrders: false, loading: false, userOrders: adjustedOrders.filter(this.validRental.bind(this)) })
      })
    }
  }

  componentDidMount() {
    ClassService.getClassDetails(this.state.props.match.params.slug, (yogaClass) => {
      console.log(yogaClass)
      this.setState({ classInfo: yogaClass })

      if(AuthService.loggedIn()) {
        this.getUserClasses()
      } else { 
        this.setState({
          loading: false
        })
      }
    })
  }

  render() {

 
 
    return (
    		<Container>
        {this.state.loading === true ? (
              <LoadingIndicator />
           ) : (

              <React.Fragment>
              <h1>{this.state.classInfo.name}</h1>
              <div className="Enter-screen">
                <Row justify="center">
                  <Col xs={12} lg={6} className="class-table">
                    <table className="center">
                      <tbody>
                      <tr>
                        <td width="25%">
                          <strong>Difficulty:</strong> { this.state.classInfo.difficulty }
                        </td>
                        <td width="25%">
                          <strong>Props:</strong> { this.state.classInfo.props }
                        </td>
                      </tr>
                      <tr>
                        <td width="25%">
                          <strong>Style:</strong> { this.state.classInfo.style }
                        </td>
                        <td width="25%">
                          <strong>Length:</strong> { this.state.classInfo.length }
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Col xs={12}>
                    { this.videoDiv() }
                  </Col>
                  <Col xs={12} className="pad-top-30">
                    { HTMLComponent.renderHTML(this.state.classInfo.content) }
                  </Col>
                  <Col xs={12} className="pad-top-30">
                    
                  </Col>
                </Row> 
              </div>
              </React.Fragment>
           )}
        </Container>
    )
  }
}


export default ClassDetails;
