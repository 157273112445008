import http from './Http'
import tokenSvc from './TokenService'

class OrderService {

	static getOrders(callback) {
		const token = tokenSvc.getToken()

		http.get('userOrders', { 'x-access-token-workoutaholic': token }, (res) => {
			callback(res)
		})
	}

	static purchase(body, callback) {
		const token = tokenSvc.getToken()

		http.post('userOrders', body, { 'x-access-token-workoutaholic': token }, res => {
			callback(res)
		})
	}
}

export default OrderService