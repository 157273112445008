import React, { Component } from 'react'
//import { Link } from 'react-router-dom'

//import logo from './logo.svg'

class Header extends Component {

  render() {
    return (
        <header className="App-header">
          <div className="App-logo App-title">
          	<div className="icon-text pad-top-30">
          		Jamie's Yoga
          	</div>
          </div>
  
        </header>
    )
  }
}

export default Header;
