import React, { Component } from 'react'
import AssetService from '../../services/AssetService'

class AssetDetails extends Component {

	constructor(props, match) {
    super(props)
    
    AssetService.getAssetDetails(props.match.params.slug, (assetInfo) => {
      console.log(assetInfo)
      if(assetInfo._mimeType) {
        switch(assetInfo._mimeType) {
          case "video/mp4":
          this.setState(
            this.asset = <div><h1>{assetInfo.name}</h1><br /><div className="Enter-screen"><video width="320" height="240" controls><source src={ assetInfo._url } type="video/mp4" />Your browser does not support the video tag.</video></div></div>
          )
          break;
          default: 
          this.setState(
            this.asset = <div><h1>{assetInfo.name}</h1><br /><div className="Enter-screen"><img className="col-xs-12" src={ assetInfo._url } alt={ assetInfo.name} /></div></div>
          )
          break;
        }
      } else {
        this.setState(
          this.asset = <div><h1>{assetInfo.name}</h1><br /><div className="Enter-screen"><img className="col-xs-12" src={ assetInfo._url } alt={ assetInfo.name} /></div></div>
        )
      }
      
    })
  }

  render() {
    return (
    		<div>
        {this.asset}
        </div>
    )
  }
}

export default AssetDetails;
