import React, { Component } from 'react'
import HTMLComponent from '../shared/HTMLComponent'
import ArticleService from '../../services/ArticleService'

class ArticleDetails extends Component {

	constructor(props, match) {
    super(props)
    
    ArticleService.getArticleDetails(props.match.params.slug, (articleInfo) => {
      console.log(articleInfo)
      this.setState(
        this.article = <div><h1>{articleInfo.name}</h1><br /><div className="Enter-screen"><img className="col-xs-12 col-sm-4" src={ articleInfo.thumb._url } alt={articleInfo.name} />{HTMLComponent.renderHTML(articleInfo.content)}</div></div>
      )
    })
  }

  render() {
    return (
    		<div>
        {this.article}
        </div>
    )
  }
}


export default ArticleDetails;
