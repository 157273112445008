import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import AuthForm from './components/auth/AuthForm'
import PwdResetForm from './components/auth/PasswordReset'
import SetPasswordForm from './components/auth/SetPasswordForm'
import Home from './components/static/Home'
import About from './components/static/About'
import Contact from './components/static/Contact'
import UserAccount from './components/user/UserAccount'
import ClassList from './components/class/ClassList'
import ClassDetails from './components/class/ClassDetails'
import ArticleList from './components/article/ArticleList'
import ArticleDetails from './components/article/ArticleDetails'
import AssetDetails from './components/asset/AssetDetails'

import HomeAdmin from './components/admin/HomeAdmin'
import AdminList from './components/admin/AdminList'
import AdminForm from './components/admin/AdminForm'

const NoMatch = ({ location }) => (
    <div>
      <h3>No match for <code>{location.pathname}</code></h3>
    </div>
)

class Main extends Component {

  render() {
    return (
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route exact path='/about' component={About}/>
          <Route exact path='/contact' component={Contact}/>
          <Route exact path='/login' component={AuthForm}/>
          <Route exact path='/passwordReset' component={PwdResetForm}/>
          <Route exact path='/set_password/:slug' component={SetPasswordForm}/>


          <Route exact path='/myAccount' component={UserAccount}/>

          <Route exact path='/classes' component={ClassList}/>
          <Route exact path='/classes/:slug' component={ClassDetails} redirect='/classes/:slug'/>
          <Route exact path='/classs/:slug' component={ClassDetails}/> 

          <Route exact path='/articles' component={ArticleList}/>
          <Route exact path='/articles/:slug' component={ArticleDetails}/>

          <Route exact path='/assets/:slug' component={AssetDetails}/>

          <Route exact path='/admin' component={HomeAdmin} />
          <Route exact path='/admin/:model/:slug' component={AdminForm}/>
          <Route exact path='/admin/:slug' component={AdminList}/>

          <Redirect from='/gallery' to="/" />
          <Redirect from='/services' to="/" />

          <Route component={NoMatch}/>

        </Switch>
    )
  }
}


export default Main;
// <Route exact path='/admin/articles/:slug' component={ArticleAdminDetails}/>