import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import AdminService from '../../services/AdminService'
import AssetService from '../../services/UploadAssetService'
import LoadingIndicator from '../shared/LoadingIndicator'
import { Container, Row } from 'react-grid-system'

class AdminForm extends Component {

  constructor(props, match) {
    super(props)

    this.state = {
      success: false,
      loading: false,
      references: 0,
      referencesRetrieved: 0
    }
    this.model = {}
    this.schema = {}
    this.modelName = props.match.params.model
    this.slugName = props.match.params.slug
    this.optionsForModel = {}
    this.hasFileUpload = false

    this.handleValueChange = this.handleValueChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    AdminService.getModelSchema(this.modelName.substr(0,props.match.params.model.length-1), (schemaInfo) => {
      console.log('schema info', schemaInfo)
      this.schema = schemaInfo
      if(this.slugName === 'new') {
        this.buildModel()
        if(Object.entries(this.optionsForModel).length === 0 && this.optionsForModel.constructor === Object) {
          this.buildForm()
        } else {
          this.buildOptionsForModelReferences()
          this.buildSelectOptions()
        }
      } else {
        AdminService.getModelsDetails(this.modelName, props.match.params.slug, (modelInfo) => {
          console.log('model info', modelInfo)
          this.model = modelInfo

          this.buildOptionsForModelReferences()
          if(Object.entries(this.optionsForModel).length === 0 && this.optionsForModel.constructor === Object) {

            this.buildForm()
          } else {

            this.buildSelectOptions()
          }
        })
      }
    })
  }

  buildSelectOptions() {
    console.log('options for model', this.optionsForModel)
    Object.keys(this.optionsForModel).forEach(key => {
            this.setState({ references: this.state.references + 1 })
            AdminService.getModelsListSync(this.optionsForModel[key], null)
              .then(res => {
       
              this.setState( this.optionsForModel[key] = res.data )
              this.setState({ referencesRetrieved: this.state.referencesRetrieved + 1 })
              if(this.state.references === this.state.referencesRetrieved) {
                // check for array of references

                this.buildForm()
              }
              })
          })
  }

  buildOptionsForModelReferences() {
    for(var key in this.schema) {
      if(this.schema[key].options.hasOwnProperty("ref")) {
        
        var modelOptions = this.schema[key].options.ref.toLowerCase() + 's'
        this.optionsForModel[key] = modelOptions
      } else if(this.schema[key].options.hasOwnProperty("type")) {
        if(this.schema[key].options.type === "Array") {
          if(this.schema[key].options.type[0].hasOwnProperty("ref")) {

            var arrayOptions = this.schema[key].options.ref.toLowerCase() + 's'
            this.optionsForModel[key] = arrayOptions
          }
        }
      } else if(key === '_file_upload' && this.slugName === 'new') { this.hasFileUpload = true }
    }
  }

  buildModel() {
    for(var key in this.schema) {
      var value = ''
      
      if(this.schema[key].options.hasOwnProperty("default")) {
        value = this.schema[key].options.default
      }
      if(this.schema[key].options.hasOwnProperty("ref")) {
        var modelOptions = this.schema[key].options.ref.toLowerCase() + 's'
        this.optionsForModel[key] = modelOptions
      }
      this.model[key] = value
      if(key === '_file_upload') { this.hasFileUpload = true }
    }
  }

  getFormInputElement(type, key, value) {
    //content in backend triggers large text area for things like articles
    if(key === 'content')
      return(<textarea className="form-content" id={key} rows="15" value={value} onChange={this.handleValueChange}></textarea>)
    //triggers file upload button
    if(key === '_file_upload') {
       if(this.slugName === 'new') {
        return(<input type="file" id={key} required accept="image/*, video/*" onChange={this.handleValueChange}/>)
       } else {
        return(<input type="file" id={key} accept="image/*, video/*" onChange={this.handleValueChange}/>)
       }
    }

    switch(type) {
      case "Boolean":
        return(<input type="checkbox" className="form-element" value={value} checked={value} id={key} onChange={this.handleValueChange} />)
      case "Date":
        if(value && value.length >9) {
          return(<input type="date" value={ value.substr(0, 10) } id={key} onChange={this.handleValueChange} />)
        } else {
          return(<input type="date" value="9999-12-31" id={key} onChange={this.handleValueChange} />)
        }
      case "ObjectID":
        if(key.charAt(0) === "_") { 
          return(<input type="text" className="form-element readonly" value={value || ''} id={key} onChange={this.handleValueChange} readOnly/>) 
        }
        // console.log('options - ', this.optionsForModel)
        if(this.optionsForModel.hasOwnProperty(key) && this.optionsForModel[key].length > 0) {

          return(
            <select id={key} value={value || ''} onChange={this.handleValueChange}>
                <option key={type + key} value={''}>None Selected</option>
                {this.optionsForModel[key] && this.optionsForModel[key].length > 0 && this.optionsForModel[key].map(key => {
                    return <option key={key._id} value={key._id}>{key.name || key.email}</option>
                })}
            </select>
          )
        }
        break;
      default:
        if(key.charAt(0) === "_") { 
          return(<input type="text" className="form-element readonly" value={value || ''} id={key} onChange={this.handleValueChange} readOnly/>) 
        } else {
          return(<input type="text" className="form-element" value={value || ''} id={key} onChange={this.handleValueChange} />)
        }
    }
  }

  getInputLine(key, value) {

    var type = ""

    if(this.schema.hasOwnProperty(key))
      type = this.schema[key].instance

    if(type === "Array") {
      console.log(this.schema[key].options.type)
      var formInputs = this.schema[key].options.type.map((entry, x) => {
   
        var forms = Object.entries(this.schema[key].$embeddedSchemaType.options).map((path, index) => {
          console.log('return - ', path, '\nx - ', x, '\nindex - ', index)
          console.log(this.schema[key])
          console.log(this.schema[key].$embeddedSchemaType.options)
          return(<div className="jumbotron" key={key + '-' + index + '-' + x}>
            <label>
            {path[0]}: {this.getFormInputElement(type, path[0], this.schema[key][x][path[0]])}
            </label>
            <br />
            </div>
            )
        })
        return [<div key={entry[x] + ' - ' + x} className="jumbotron"><h3>{key.substr(0,key.length-1) + ' - ' + (x+1)}</h3>{forms}</div>]
      })
      return formInputs
    }
    return this.getFormInputElement(type, key, value)
  }

  buildForm() {
    this.setState(
      this.modelForm = Object.entries(this.schema).map((key, index) => {
        if(this.schema.hasOwnProperty(key[0])) {

          var value = null
          if(this.model.hasOwnProperty(key[0])) {
            value = this.model[key[0]]
          }
          return(<div className="pad-top-10" key={index.toString()}>
            <label>
            {key[0]}:&nbsp;&nbsp;&nbsp;
            </label>
            { this.getInputLine(key[0], value) }
            </div>) 
        }
        return null
      })  
    )
  }

  handleValueChange(event) {
    if(event.target.type === "checkbox") {
      this.model[event.target.id] = !this.model[event.target.id]
    } 
    else if(event.target.type === "file") {
      this.model[event.target.id] = event.target.files[0]
    }
    else {
      this.model[event.target.id] = event.target.value
    }
    this.buildForm(this.schema)
  }

  handleSubmit(event) {
    this.setState({ loading: true })
    event.preventDefault();
    if(this.hasFileUpload === true) {
      AssetService.getSignedRequest(this.model, (res) => {
        if(res.success === true){
          AssetService.uploadFile(this.model, res.signed_request, res.url, (res) => {
              this.setState({
                success: true,
                loading: false
              })
              console.log(res)
          })
        }
      })
    }
    else if(this.slugName === 'new') {
      AdminService.postModel(this.modelName, this.model, (res) => {
        if(res.success === true) {
          this.setState({
            success: true,
            loading: false
          })
        } else {
          alert(res.message)
        }
        console.log(res)
      })
    }
    else {
      AdminService.putModel(this.modelName, this.model._id, this.model, (res) => {
        if(res.success) {
          this.setState({
            success: true,
            loading: false
          })
        } else {
          alert(res.message)
        }
        console.log(res)
      })
    }

  }

  render() {
    if(this.state.success) {
      return <Redirect to={"/admin/" + this.modelName.substring(0, this.modelName.length-1)} />
    }
    return (
      <Container>
      <h1>Edit </h1>
      <div className="fullScreen">
      <Row>
      <form className="Enter-screen fulWidth" encType="multipart/form-data" onSubmit={this.handleSubmit}>
        {this.modelForm}
        <br />
        <input type="submit" value="Submit" className="btn center"/>
      </form>
      </Row>
      {this.state.loading === true ? (
            <LoadingIndicator />
            ) : (
            <div> </div>
            )
        }
      </div>
      <pre className="jumbotron">
        { JSON.stringify(this.model, null, '\t') }
      </pre>
      </Container>
    );
  }
}

export default AdminForm;
