import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-grid-system'

class Home extends Component {

	constructor(props) {
    super(props);

    this.state = {
    	classes:
	 		[{
	    	id: 1,
	      url: 'http://harmonyyoga.com/class-schedule',
	      name: 'Mon 5:00-6:15 pm Harmony Yoga'
	    },
	    {
	    	id: 2,
	      url: 'http://harmonyyoga.com/class-schedule',
	      name: 'Wed 5:00-6:15 pm Harmony Yoga'
	    },
	    {
	    	id: 3,
	      url: 'http://harmonyyoga.com/class-schedule',
	      name: 'Sun 10-11:30 am Harmony Yoga'
	    }]
	  }

	  this.classLinks = this.state.classes.map((item) =>
    	<Col xs={12} sm={4} key={item.id}>
   			<a href={item.url} target="_blank" rel="noopener noreferrer" className="class-button">{item.name}</a>
   		</Col>
  
    )
	}

	render() {
		return (
				<Container>
			    <h1>Home</h1>
			    <div className="Enter-screen fullWidth">
			    	<Row justify="center">
			        <Col xs={12} sm={9} md={9} lg={8}>
        				<img className="image-rounded" alt="Online Yoga Classes" width="100%" src="https://yoga-jams.s3.amazonaws.com/JamieBannerHome.jpg" />
				    	</Col>
				    </Row>
			    	<Row className="pad-top-10">
				    	<Col>
					    	For her weekly schedule announcements, check back regularly or follow her on Instagram: @jamie_horgan
					    	<br /><br />
					    	<strong>Jamie will be teaching virtually with Harmony Yoga three days per week</strong>


					    	<Row className="pad-top-30">
					    		{this.classLinks}
					    	</Row>	
							</Col>
			    	</Row>
			    </div>
			  </Container>
		)
	}
}


export default Home;
