import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import AuthService from '../../services/AuthService'
import TokenService from '../../services/TokenService'
import { Container, Row, Col } from 'react-grid-system'
import LoadingIndicator from '../shared/LoadingIndicator'

class SetPwdForm extends Component {
  constructor(props, match) {
    super(props);
    this.state = {
      password: '',
      props: props,
      badLink: false,
      user: {},
      loading: true,
      success: false
    };

    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.header = function() {
      if(this.props.dissolve) {
        return(<></>)
      } else {
        return(<h1>Set New Password</h1>)
      }
    }

    AuthService.getUserForPasswordReset(this.state.props.match.params.slug, (res) => {
      if(res.success === false) {
        this.setState({ loading: false, badLink: true})
      } else {
        this.setState({user: res.user, loading: false})
      }
    })
  }

  handlePasswordChange(event) {
    this.setState({password: event.target.value});
  }

  handleSubmit(event) {
    AuthService.setPassword({ password: this.state.password, resetToken: this.state.props.match.params.slug, email: this.state.user.email }, res => {
      const prefix = (res.message && res.success && res.success === true) ? "Success: " : "Error: "
      alert(prefix + res.message)
      if(res.success === true) {
        TokenService.setToken(res.token)
        this.setState({ success:  true })
      }
    })
    event.preventDefault();
  }

  render() {
    if(this.state.success === true) {
      // need to handle redirect back
      if(this.props.dissolve) {
        this.props.onSuccess()
      } else {
        return <Redirect to={ "/myAccount" } /> //if not go to account - not admin`````````
      }
    } else if (this.state.success === false && this.state.badLink === true) {
      return <Redirect to={ "/" } /> 
    }
    return (
      <Container>
      { this.header() }
      <div className="Enter-screen">
      {this.state.loading === true ? (
              <LoadingIndicator />
           ) : (
              <React.Fragment>
              <Row className="pad-top-30">
                <Col xs={12}>
                  <form className="center-text jumbotron" onSubmit={this.handleSubmit}>
                    
                    <h3>Set a new password.</h3>
                    <label>
                      Password<br />
                      <input type="password" value={this.state.password} autoCapitalize="none" autoComplete="email" onChange={this.handlePasswordChange} className="form-element" />
                    </label>
                    <br />
                    <br />
                    <input type="submit" value="Submit" className="submit-btn"/>
             
                  </form>
                </Col>
              </Row>
              </React.Fragment>
           )}
      </div>
      </Container>
    );
  }
}

export default SetPwdForm;
