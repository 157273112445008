import axios from 'axios'
import tokenSvc from './TokenService'
import environment from './Environment'

const config = environment.config

class Http {

	constructor() {
		// Add a request interceptor
		axios.interceptors.request.use((config) => {
		    // Do something before request is sent
		    const token = tokenSvc.getToken()
		    console.log('interceptor request - ' + token)
		    if(token != null) {
		    	config.headers = { 'x-access-token-workoutaholic': token }
		    }
		    return config;
		  }, (error) => {
		    // Do something with request error
		    return Promise.reject(error)
		  })
		 
		// Add a response interceptor
		axios.interceptors.response.use((response) => {
		    // Do something with response data

		    if (response.status === 403) {
		    	console.log('403 clear token')
	            tokenSvc.setToken();
	            //$injector.get('$state').go('login'); // or use .transitionTo
	        } 

	         // return the errors from the server as a promise
		    return Promise.reject(response)

		  }, (error) => {
		    // Do something with response error
		    return Promise.reject(error);
		  });
	}

	static get(path, headers, callback) {
		axios.get(config.baseURL + path, { 
			method: 'GET',
			mode: 'cors',
			headers: headers 
		})
    .then((res) => {
      callback(res.data)
    })
    .catch((err) => {
      console.log('http logged - ' + err)
      callback({ success: false, })
    })
	}

	static getAwait(path, headers) {
		return axios.get(config.baseURL + path, { 
			method: 'GET',
			mode: 'cors',
			headers: headers 
		})
	}

	static post(path, body, headers, callback) {
		axios.post(config.baseURL + path, body, { 
					method: 'POST',
					mode: 'cors',
					headers: headers 
				})
	      .then((res) => {
	      	console.log(res)
	        callback(res.data)
	      })
	      .catch((err) => {
	      	console.log("error ERROR")
	        console.log(err)
	      })
	}

	static put(path, params, body, headers, callback) {
		axios.put(config.baseURL + path + '/' + params, body, { 
					method: 'PUT',
					mode: 'cors',
					headers: headers 
				})
	      .then((res) => {
	      	
	        callback(res.data)
	      })
	      .catch((err) => {
	        console.log(err)
	      })
	}

	static delete(path, headers, callback) {
		axios.delete(config.baseURL + path, { 
					method: 'DELETE',
					mode: 'cors',
					headers: headers 
				})
	      .then((res) => {
	      	
	        callback(res.data)
	      })
	      .catch((err) => {
	        console.log(err)
	      })
	}
}

export default Http