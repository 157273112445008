import http from './Http'

class ClassService {

	static getClasses(callback) {
		http.get('classs', {}, (res) => {
			callback(res.filter(yogaClass => yogaClass && yogaClass.published === true))
		})
	}

	static getClassDetails(params, callback) {

		http.get('classs/' + params, {}, res => {
			callback(res)
		})
	}
}

export default ClassService