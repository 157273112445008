import React, { Component } from 'react'

import MailerService from '../../services/MailerService'

class ContactForm extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      email: '',
      message: ''
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }

  handleMessageChange(event) {
    this.setState({message: event.target.value});
  }

  handleSubmit(event) {
    if(this.state.email.trim() === '' || this.state.message.trim() === '') {
      alert("All fields are required.")
    } else {

      MailerService.sendMessage(this.state, (res) => {
        alert(res.message)
        this.setState({
          email: '',
          message: ''
        })
      })
    }
    event.preventDefault();

  }

  render() {
    return (
      <div className="fullWidth">
      <h1>Contact</h1>
      <form className="Enter-screen" onSubmit={this.handleSubmit}>
        <label className="form-label">
          How can I can get back you?<br />
          <input type="text" className="form-element" value={this.state.email} onChange={this.handleEmailChange} placeholder='Email'/>
        </label>
        <br />
        <label>
          What can I help you with?<br />
          <textarea className="form-content" rows="8" value={this.state.message} onChange={this.handleMessageChange} />
        </label>
        <br />
        <br />
        <input type="submit" value="Submit" className="submit-btn" />
      </form>
      </div>
    );
  }
}

export default ContactForm;
