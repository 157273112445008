import React, { Component } from 'react'
import './App.css'

import Main from './Main'
import Header from './components/static/Header'
import Footer from './components/static/Footer'
import NavigationBar from './components/static/NavigationBar'

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      comp: 'AuthForm'
    };

  }

  render() {
    return (
      <div className="App">

        <NavigationBar />
        <Header />
        <Main />
        <Footer />
      </div>
    );
  }
}


export default App;
