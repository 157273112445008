import React, { Component } from 'react'

class LoadingIndicator extends Component {
	render() {
		return (
				<div className="fullWidth pad-top-30">
					<img className="App-loading" alt="loading" src="https://yoga-jams.s3.amazonaws.com/ohm-symbol.png" />
				</div>
		)
	}
}

export default LoadingIndicator