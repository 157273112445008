import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import LoadingIndicator from '../shared/LoadingIndicator'
import withAuth from '../shared/RequireAuth'

import AdminService from '../../services/AdminService'

class AdminList extends Component {

	constructor(props, match) {
    super(props)

    this.handleDelete = this.handleDelete.bind(this)
    this.model = props.match.params.slug + 's'

    this.state = {
      listItems: [],
      loading: true
    }

    this.buildList()
  }

  buildList() {
    AdminService.getModelsList(this.model, res => {
  
      this.setState({
        loading: false,
        listItems: res.map((item, index) =>

          <tr key={index}>
            <td>{item._id}</td>
            <td>{item.name || item.email}</td>
            <td>
              <Link to={'/' + this.model + '/' + item.slug} className="btn">Show</Link>&nbsp;
              <Link to={'/admin/' + this.model + '/' + item._id} className="btn">Edit</Link>&nbsp;
              <button onClick={this.handleDelete} value={item._id} className="btn">Delete</button>
            </td>
          </tr>
        ) 
      })
    })
  }

  handleDelete(event) {
      
      AdminService.deleteModelInstance(this.model, event.target.value, (res) => {
        alert(JSON.stringify(res))

        this.buildList()
      })
  }

  render() {
    return (
    		<div>
        <h1>{this.model}</h1>
        <div className="fullWidth"><Link to={'/admin/' + this.model + '/new'} className="btn">
           New
        </Link></div>
        <br />
        <table className="table pad-top-30">
          <thead>
            <tr>
              <th>_id</th>
              <th>Name</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {this.state.listItems}
          </tbody>
        </table>
        {this.state.loading === true ? (
            <LoadingIndicator />
            ) : (
            <div> </div>
            )
        }
        </div>
    )
  }
}


export default withAuth(AdminList)
