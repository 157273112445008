import React, { Component } from 'react'

class HTMLComponent extends Component {

  static createMarkup(rawHTML) {
      return {__html: rawHTML };
   }

   static renderHTML(rawHTML) {
      return <div dangerouslySetInnerHTML={this.createMarkup(rawHTML)} />;
   }

}

export default HTMLComponent